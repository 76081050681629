<template>
    <div>
        <van-nav-bar title="会议报名" left-arrow @click-left="onClickLeft" />
        <div class="title">2024年</div>
        <div class="title">陕西省职业教育活动周启动仪式</div>
        <div class="title">参会报名信息</div>
        <van-cell-group>
            <van-field readonly clickable label="地市" :value="form.city" is-link required placeholder="选择地市"
                @click="showPicker = true" />
            <van-popup v-model="showPicker" position="bottom">
                <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
            </van-popup>
            <van-field v-model="form.department" label="单位" placeholder="请填写单位全称" maxlength="50" required />
            <van-field v-model="form.name" label="姓名" placeholder="请填写姓名" maxlength="50" required />
            <van-field v-model="form.position" label="职务" placeholder="请填写职务" maxlength="50" required />
            <van-field v-model="form.telPhone" label="手机号码" placeholder="请填写手机号码" maxlength="11" required>
                <van-button slot="button" type="primary" :disabled="timerNum > 0" size="small" plain @click="handleSms">
                    {{ timerNum > 0 ? timerNum + "S后重试" : "发送验证码" }}
                </van-button>
            </van-field>
            <van-field v-model="form.smsCode" label="短信验证码" placeholder="请填写短信验证码" maxlength="6" required />
            <van-field v-model="form.remark" type="textarea" label="备注" maxlength="100" placeholder="请填写备注" />
        </van-cell-group>
        <div style="text-align: center;">
            <van-button plain type="primary" style="margin: 10px auto;" @click="handleJoin">确认报名</van-button>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog } from 'vant-green';
import $valid from '@/utils/valid'
import { fetchSmsCode, fetchJoinMeeting } from '@/api/meeting'

export default {
    data() {
        return {
            form: {
                city: '',
                department: '',
                name: '',
                position: '',
                telPhone: '',
                remark: '',
                smsCode: ''
            },
            showPicker: false,
            columns: ["省直属", "西安市", "铜川市", "宝鸡市", "咸阳市", "渭南市", "延安市", "汉中市", "榆林市", "安康市", "商洛市", "杨凌示范区"],
            timeIntervalSms: null,
            timerNum: 0,
            serverCode: '',//服务器返回的短信验证码
        };
    },
    beforeDestroy() {
        if (this.timeIntervalSms) {
            clearInterval(this.timeIntervalSms)
        }
    },
    methods: {
        onClickLeft() {
            this.$router.push('/')
        },
        onConfirm(value) {
            // Toast(`当前值：${value}, 当前索引：${index}`);
            this.form.city = value;
            this.showPicker = false;
        },
        handleSms() {
            if (!$valid.isPhone(this.form.telPhone)) {
                Toast(`请填写正确的手机号码`);
                return
            }
            fetchSmsCode({ phone: this.form.telPhone }).then(res => {
                if (res.code == 0) {
                    this.serverCode = res.data
                    this.timerNum = 60
                    const _this = this
                    // 定时刷新token
                    this.timeIntervalSms = setInterval(() => {
                        _this.timerNum--
                        if (_this.timerNum === 0) {
                            clearInterval(_this.timeIntervalSms)
                        }
                    }, 1000)
                }
            })

        },
        /**确认报名 */
        handleJoin() {
            if (!this.form.city) {
                Toast(`请选择地市`);
                return
            }
            if (!this.form.department) {
                Toast(`请填写单位名称`);
                return
            }
            if (!this.form.name) {
                Toast(`请填写姓名`);
                return
            }
            if (!this.form.position) {
                Toast(`请填写职务`);
                return
            }
            if (!$valid.isPhone(this.form.telPhone)) {
                Toast(`请填写正确的手机号码`);
                return
            }
            if (!this.form.smsCode) {
                Toast(`请填写短信验证码`);
                return
            }
            if (this.form.smsCode !== this.serverCode) {
                Toast(`短信验证码不正确`);
                return
            }
            fetchJoinMeeting(this.form).then(res => {
                if (res.code === 0) {
                    Dialog.alert({
                        title: '报名成功',
                        message: '您已报名成功，感谢您的参与！',
                        confirmButtonText: '我知道了'
                    }).then(() => {
                        // on close
                        this.$router.push('/user/wenxintishi')
                    });
                }
            })
        }
    },
};
</script>

<style scoped>
.title {
    text-align: center;
    font-size: 24px;
    color: #2b72c0;
}
</style>
